<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="100px"
    >
      <el-form-item label="报告员工：" prop="employeeName">
        <span>{{ dataForm.employeeName }}</span>
      </el-form-item>
      <el-form-item label="报告标题：" prop="title">
        <span>{{ dataForm.title }}</span>
      </el-form-item>
      <el-form-item label="报告时间：" prop="createDate">
        <span>{{ dataForm.createDate }}</span>
      </el-form-item>
      <el-form-item label="报告内容：" prop="content">
        <div v-html="dataForm.content"></div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        userId: "",
        employeeId: "",
        employeeName: "",
        title: "",
        content: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
      dataRule: {},
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.reporting.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
            }
          });
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = JSON.parse(JSON.stringify(this.dataForm));

          if (this.dataForm.id) {
            // 修改
            this.$http.reporting.update(requestDate).then((res) => {
              if (res && res.code === 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.reporting.save(requestDate).then((res) => {
              if (res && res.code === 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
